/* eslint-disable import/prefer-default-export */
import { graphql, useStaticQuery } from 'gatsby';

export const usePartners = () => {
  const data = useStaticQuery(graphql`
    query {
      allPartnersJson {
        partners: nodes {
          name
          logo
          url
        }
      }
    }
  `);

  return data.allPartnersJson.partners;
};

export default usePartners;
