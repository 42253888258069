import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../components/Layout';
import Link from '../components/Link';
import useCorrectLanguage from '../hooks/useCorrectLanguage';
import usePartners from '../hooks/usePartners';

const useStyles = makeStyles(theme => ({
  cardMedia: {
    height: theme.spacing(12),
  },
}));

const Partners = ({ pageContext: { language } }) => {
  useCorrectLanguage(language);

  const { t } = useTranslation();
  const partners = usePartners();
  const classes = useStyles();

  return (
    <Layout translationsAvailable>
      <Typography variant="h1">{t('Partenaires')}</Typography>

      <Grid container spacing={2}>
        {partners.map(({ name, url, logo }) => (
          <Grid item md={3} key={name}>
            <Card
              style={{ marginTop: '1em' }}
              variant="outlined"
              key={name}
            >
              <CardMedia image={logo} className={classes.cardMedia} />
              <CardContent>
                <Typography variant="h3">
                  <Link to={url}>
                    {name}
                  </Link>
                </Typography>

                <Typography
                  variant="subtitle1"
                  component={Link}
                  to={url}
                  color="textSecondary"
                >
                  {url}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

      </Grid>

    </Layout>
  );
};

export default Partners;
