import i18n from '../i18n';
import { languages } from '../locales';

const [defaultLang] = languages;

const useCorrectLanguage = (language = defaultLang) => {
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }
};

export default useCorrectLanguage;
